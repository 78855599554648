import { ReferralWithoutPII } from '@getvim/internal-vim-os-sdk/types';
import { getReferralIdMapping } from '@getvim/vim-connect/src/infra/common/api/referral/referral-id-mapping';
import { ReferralExternalIdSource } from '@getvim/platform-types';
import { createLogger } from '../../utils';
import dayjs from 'dayjs';

const logger = createLogger('getReferralMode');

export enum ReferralModeEnum {
  CREATE = 'create',
  EDIT = 'edit',
}

const ATHENA_EHR = 'athena';

const getReferralMode = async ({
  referral,
  ehrVendor,
  shouldGetReferralModeByDateFF,
}: {
  referral: ReferralWithoutPII;
  ehrVendor?: string;
  shouldGetReferralModeByDateFF?: boolean;
}): Promise<ReferralModeEnum> => {
  const { identifiers } = referral ?? {};
  const { ehrReferralId } = identifiers || {};

  let referralMode;
  try {
    const result = await getReferralIdMapping({
      ehrId: ehrReferralId ?? '',
      source: ReferralExternalIdSource.OPTUM,
    });

    referralMode = result ? ReferralModeEnum.EDIT : ReferralModeEnum.CREATE;

    logger.info('Referral mode', { noPHI: true, mode: referralMode });

    const createdDate = referral?.basicInformation?.createdDate;
    const shouldGetAthenaModeByDate =
      shouldGetReferralModeByDateFF &&
      !result &&
      ehrVendor?.toLowerCase() === ATHENA_EHR &&
      createdDate;

    if (shouldGetAthenaModeByDate) {
      const today = dayjs().startOf('day');
      const isReferralFromToday = dayjs(createdDate).isSame(today, 'day');

      referralMode = isReferralFromToday ? ReferralModeEnum.CREATE : ReferralModeEnum.EDIT;

      logger.info('Recalculated Referral mode for Athena - by createdDate ', {
        noPHI: true,
        mode: referralMode,
      });
    }

    return referralMode;
  } catch (error) {
    logger.error('getReferralIdMapping request failed', {
      ehrId: ehrReferralId,
      err: error,
      noPHI: true,
    });
  }

  return ReferralModeEnum.CREATE;
};

export { getReferralMode };
