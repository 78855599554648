import '@getvim/atomic-ui/assets/styles/main.less';
import { ThemeVariablesWrapper, themes } from '@getvim/components-hooks-use-theme';
import { FeatureFlagProvider } from '@getvim/feature-flags-react';
import { VimSDKProvider } from '@getvim/internal-vim-os-sdk/react';
import { TelemetryProvider } from '@getvim/opentelemetry-sdk/react';
import 'bootstrap/less/bootstrap.less';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import AppWrapper from './components/AppWrapper';
import ConfigurationComponent from './components/configuration';
import { RequiredStateWrapper } from './components/RequiredStateWrapper';
import './index.less';
import { GlobalStateProvider } from './stores/GlobalStore';
import { APP_ID, AppEnv } from './types';
import { featureFlagsConfig } from './utils/featureFlags';
import sdkOptions from './sdkOptions';

const appEnv: AppEnv = (window as any).$vim_environment?.APP_ENV;

const AppSdkWrapper = () => {
  return (
    <TelemetryProvider
      options={{
        serviceName: APP_ID,
        env: appEnv,
        enableLogs: appEnv === AppEnv.DEV,
        enableMetrics: appEnv === AppEnv.DEV,
        enableTraces: appEnv === AppEnv.DEV,
      }}
    >
      <VimSDKProvider {...sdkOptions}>
        <ThemeVariablesWrapper theme={themes.optum}>
          <GlobalStateProvider>
            <RequiredStateWrapper>
              <FeatureFlagProvider {...featureFlagsConfig}>
                <AppWrapper />
              </FeatureFlagProvider>
            </RequiredStateWrapper>
          </GlobalStateProvider>
        </ThemeVariablesWrapper>
      </VimSDKProvider>
    </TelemetryProvider>
  );
};

const ConfigurationComponentWrapper = () => {
  return (
    <FeatureFlagProvider>
      <ConfigurationComponent />
    </FeatureFlagProvider>
  );
};

const router = createBrowserRouter([
  {
    element: <AppSdkWrapper />,
    index: true,
  },
  {
    element: <ConfigurationComponentWrapper />,
    path: '/console-ui',
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<RouterProvider router={router} />);
