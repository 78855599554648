import {
  GET_REFERRAL_UTILITY_CONFIG_QUERY,
  GET_REFERRAL_UTILITY_DEPLOY_CONFIGS_QUERY,
  SAVE_REFERRAL_UTILITY_DEPLOY_CONFIG_QUERY,
  SAVE_REFERRAL_UTILITY_CONFIG_QUERY,
} from './queries';
import {
  ReferralUtilityPutConfiguration,
  ReferralUtilityGetConfigurationResponse,
  ReferralUtilityPutConfigurationResponse,
  ReferralUtilityDeployConfig,
} from './types';
import { BaseInternalApi } from '../baseApi';
import { AppConfig } from '../../types';
import { omitDeep } from '@apollo/client/utilities';

export class ReferralUtilityApi extends BaseInternalApi {
  constructor() {
    super('referralUtilityApi', '/referral-utility-bff/api');
  }

  public async getConfiguration(): Promise<ReferralUtilityGetConfigurationResponse> {
    const gqlClient = await this.getClient();

    const { data } =
      (await gqlClient.query<{
        referralUtilityGetConfiguration: ReferralUtilityGetConfigurationResponse;
      }>({
        query: GET_REFERRAL_UTILITY_CONFIG_QUERY,
        variables: {},
        fetchPolicy: 'no-cache',
      })) ?? {};

    // Removing the __typename field from the response, as it is not needed for the client.
    return omitDeep(data?.referralUtilityGetConfiguration, '__typename');
  }

  public async saveConfiguration(input: {
    configuration: AppConfig;
  }): Promise<ReferralUtilityPutConfigurationResponse | undefined> {
    const gqlClient = await this.getClient();

    const { data } =
      (await gqlClient.mutate<
        {
          referralUtilityPutConfiguration: {
            configuration: ReferralUtilityPutConfigurationResponse;
          };
        },
        { input: ReferralUtilityPutConfiguration }
      >({
        mutation: SAVE_REFERRAL_UTILITY_CONFIG_QUERY,
        variables: { input },
        fetchPolicy: 'no-cache',
      })) ?? {};

    return data?.referralUtilityPutConfiguration?.configuration;
  }

  public async getDeployConfigsList(): Promise<ReferralUtilityDeployConfig[]> {
    const gqlClient = await this.getClient();

    const { data } =
      (await gqlClient.query<{
        referralUtilityGetDeployConfigs: ReferralUtilityDeployConfig[];
      }>({
        query: GET_REFERRAL_UTILITY_DEPLOY_CONFIGS_QUERY,
        variables: {},
        fetchPolicy: 'no-cache',
      })) ?? {};

    // Removing the __typename field from the response, as it is not needed for the client.
    return omitDeep(data?.referralUtilityGetDeployConfigs, '__typename');
  }

  public async addDeployConfig(input: {
    deployConfig: string;
  }): Promise<ReferralUtilityDeployConfig | undefined> {
    const gqlClient = await this.getClient();

    const { data } =
      (await gqlClient.mutate<
        {
          referralUtilityPutDeployConfig: {
            deployConfig: string;
          };
        },
        { input: ReferralUtilityDeployConfig }
      >({
        mutation: SAVE_REFERRAL_UTILITY_DEPLOY_CONFIG_QUERY,
        variables: { input },
        fetchPolicy: 'no-cache',
      })) ?? {};

    return data?.referralUtilityPutDeployConfig;
  }
}
