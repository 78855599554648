import { ReferralPriority } from '@getvim-os/types';
import { getProviderBySpecialist } from './getPoviderBySpecialist';
import { formatReason, getFormatResponseToNotes } from './getNotesFormatting';
import { UpdatableReferral } from '@getvim/internal-vim-os-sdk/types';
import { createLogger } from '../../utils';
import { getLeadingReachNotesFormating } from './getLeadingReachNotesFormating';
import { ReferralUtilityResponseReferralResult } from '../../api/optum/types';
import { removeNullsValueProps } from '../../utils/filterNullValues';
import { withAsyncErrorHandling } from '../../utils/withErrorHandling';

const logger = createLogger('getUpdateReferralByQueryResponse');

function shouldUseLeadingReachProviderNotes(
  data: ReferralUtilityResponseReferralResult,
  shouldUseLeadingReachProvider: boolean,
) {
  const { provider } = data;
  const isLeadingReach = provider?.identifiers.find(
    (i) => i.system === 'https://leadingreach.com/provider',
  );
  logger.info('Check shouldUseLeadingReachProviderNotes', {
    noPHI: true,
    shouldUseLeadingReachProvider,
    isLeadingReach: !!isLeadingReach,
    referralId: data?.referralId,
  });
  return shouldUseLeadingReachProvider && isLeadingReach;
}

const getUpdateReferralByQueryResponseFunc = async (
  data: ReferralUtilityResponseReferralResult,
  shouldUseLeadingReachProvider: boolean,
  shouldUseLeadingReachProviderReferToName: boolean,
): Promise<UpdatableReferral> => {
  const {
    specialist,
    specialtyMappingDescription: specialty,
    beginDate,
    endDate,
    referralReason,
    urgency,
    diagnosisCodes,
    numberOfVisits,
    provider,
  } = data;

  const targetProviderPayload = getProviderBySpecialist(specialist, specialty);

  logger.info('Before Leading Reach Provider modification', {
    noPHI: true,
    shouldUseLeadingReachProvider,
    shouldUseLeadingReachProviderReferToName,
    targetProviderPayload: targetProviderPayload?.demographics,
  });
  if (shouldUseLeadingReachProviderReferToName && provider) {
    const { identifiers } = provider;
    if (identifiers.length) {
      (targetProviderPayload as any).demographics = {
        firstName: '',
        lastName: identifiers[0].value,
      };
    }
  }

  logger.info('After Leading Reach Provider modification', {
    noPHI: true,
    shouldUseLeadingReachProvider,
    targetProviderPayload: targetProviderPayload?.demographics,
  });
  return removeNullsValueProps({
    targetProvider: targetProviderPayload!,
    basicInformation: {
      startDate: beginDate,
      endDate,
      reasons: referralReason ? [formatReason(referralReason)] : undefined,
      priority: ReferralPriority[urgency?.toUpperCase()],
      specialty,
      notes: shouldUseLeadingReachProviderNotes(data, shouldUseLeadingReachProvider)
        ? getLeadingReachNotesFormating(data)
        : await getFormatResponseToNotes(data),
      numberOfVisits,
    },
    conditions: {
      diagnosis: diagnosisCodes?.map(({ description, value }) => {
        return { description, code: value };
      }),
    },
  });
};

export const getUpdateReferralByQueryResponse = withAsyncErrorHandling<
  typeof getUpdateReferralByQueryResponseFunc
>(getUpdateReferralByQueryResponseFunc);
