import React, { FC, useCallback, useEffect } from 'react';
import useEhrStateHandlers from '../hooks/useEhrStateHandlers';
import { useEnrichLoggerMetadata } from '../hooks/useEnrichLoggerMetadata';
import { useGlobalState } from '../stores/GlobalStore';
import { GlobalStateActionType } from '../stores/globalStore.types';
import { AppConfig, Screens } from '../types';
import './App.less';
import IframeRenderer from './iframe-renderer/IframeRenderer';
import SimpleLoading from './loading/SimpleLoading';
import WritebackLoading from './loading/WritebackLoading';
import { ReactComponent as WriteBackErrorSVG } from './writeback/write-back-error.svg';

const App: FC<{ appConfig: AppConfig }> = ({ appConfig }) => {
  useEhrStateHandlers({ appConfig });
  const { state, dispatch } = useGlobalState();

  const messageEventHandler = useCallback(
    async (message) => {
      if (message.data === 'referral-submit') {
        return dispatch({
          type: GlobalStateActionType.ON_SUBMIT,
        });
      }
    },
    [dispatch],
  );

  useEffect(() => {
    window.addEventListener('message', messageEventHandler);
    return () => window.removeEventListener('message', messageEventHandler);
  }, [messageEventHandler]);

  const onClose = useCallback(() => {
    return dispatch({
      type: GlobalStateActionType.ON_CLOSE, // TODO ON_CLOSE type should be there
    });
  }, [dispatch]);

  const { url, screen } = state;

  useEnrichLoggerMetadata();

  const modesDisplay = {
    [Screens.None]: <div />,
    [Screens.Loading]: (
      <div className="frame-container-hub">
        <SimpleLoading />
      </div>
    ),
    [Screens.LoadingWriteBack]: <WritebackLoading />,
    [Screens.WriteBackError]: (
      <div className="frame-container-hub">
        <ErrorWriteBackFailed onClose={onClose} />
      </div>
    ),
    [Screens.OptumSearchProvider]: (
      <div className="frame-container-hub">
        <IframeRenderer url={url} />
      </div>
    ),
  };

  return modesDisplay[screen];
};

const ErrorWriteBackFailed = ({ onClose }) => (
  <div className="error-iframe">
    <WriteBackErrorSVG />
    <div className="error-iframe-title">Ooops...</div>
    <div className="error-iframe-text">
      Failed to update the referral. <br /> Please make sure to update it manually.
    </div>
    <div className="error-iframe-buttons-container">
      <button className="error-iframe-cancel-button" type="button" onClick={onClose}>
        Close
      </button>
    </div>
  </div>
);

export default App;
