import React, { useEffect, useState } from 'react';
import debounce from 'debounce';
import { useFeatureFlag, Team } from '@getvim/feature-flags-react';
import './index.less';
import Form from '@rjsf/core';
import { cloneDeep } from 'lodash-es';
import { internalApi } from '../../api';
import schema from './schema.json';
import uiSchema from './ui-schema.json';
import { OrganizationTag } from '../../types';
import SimpleLoading from '../loading/SimpleLoading.tsx';

const searchParams = new URLSearchParams(window.location.search);

const ConfigurationComponent = () => {
  const [organizationConfig, setOrganizationConfig] = useState(null);
  const [organization, setOrganization] = useState(null);
  const [deployConfigsList, setDeployConfigsList] = useState(null);
  const [extendedSchema, setExtendedSchema] = useState(null);
  const [loading, setLoading] = useState(false);
  const [uiActiveSchema, setUiActiveSchema] = useState(uiSchema);
  const [bulkInsuranceInput, setBulkInsuranceInput] = useState('');
  const [bulkDeployConfigsInput, setBulkDeployConfigsInput] = useState('');

  const [shouldFetchDeployConfigsFromOptumBff] = useFeatureFlag({
    flagName: 'referral_utility.shouldFetchDeployConfigsFromOptumBff',
    team: Team.Interfaces,
    defaultValue: false,
  });

  const vimSignature = searchParams.get('vimSignature');
  internalApi.referralUtilityApi.setAccessToken(vimSignature);

  if (organization?.tag === OrganizationTag.ClinicTest) {
    schema.properties.optumNonProdUrl = {
      type: 'boolean',
      title: 'Should use Optum stage env (NON-PROD)',
      description: '',
    };
  }
  function generateUiSchema(organizationType) {
    const isOrganizationType = !organizationType;
    return {
      ...uiSchema,
      optumDeployConfiguration: {
        'ui:disabled': isOrganizationType,
      },
      autoPopUpMode: {
        'ui:disabled': isOrganizationType,
      },
      supportAllInsurances: {
        'ui:disabled': isOrganizationType,
      },
      insurancesPlanList: {
        'ui:disabled': isOrganizationType,
      },
    };
  }

  async function saveConfiguration(data) {
    try {
      await internalApi.referralUtilityApi.saveConfiguration({ configuration: data });
      setUiActiveSchema(generateUiSchema(data?.organizationType));
      setOrganizationConfig(data);
    } catch (e) {
      console.error(e);
    }
  }

  async function addNewDeployConfigurations(data) {
    try {
      await Promise.all(
        data.map((deployConfig) => {
          return internalApi.referralUtilityApi.addDeployConfig({ deployConfig });
        }),
      );
    } catch (e) {
      console.error(e);
    }
  }

  async function getOptumInitialDetails() {
    const [configurationData, deployConfigs] = await Promise.all([
      internalApi.referralUtilityApi.getConfiguration(),
      getAvailableDeployConfigs(),
    ]);

    return {
      configurationData,
      deployConfigs,
    };
  }

  async function getAvailableDeployConfigs() {
    const data = await internalApi.referralUtilityApi.getDeployConfigsList();

    return data.map((item) => item?.deployConfig);
  }

  async function handleSchemaExtending() {
    const extended = cloneDeep(schema);
    extended.properties.optumDeployConfiguration.enum = [...deployConfigsList];

    setExtendedSchema(extended);
  }

  useEffect(() => {
    if (deployConfigsList) {
      handleSchemaExtending();
    }
  }, [deployConfigsList]);

  useEffect(() => {
    if (!vimSignature) {
      return;
    }
    setLoading(true);
    getOptumInitialDetails()
      .then(({ configurationData, deployConfigs }) => {
        const { organization, configuration } = configurationData;
        setUiActiveSchema(generateUiSchema(configuration?.organizationType));
        setOrganizationConfig(configuration);
        setOrganization(organization);

        setDeployConfigsList(deployConfigs);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [vimSignature]);

  const debouncedSave = debounce(async (data) => {
    try {
      await saveConfiguration(data);
    } catch (e) {
      console.error(e);
    }
  }, 1500); // 1.5 s delay

  const handleSubmitConfiguration = (event) => {
    event.preventDefault();
    const insurancesList = `${bulkInsuranceInput}`.split(',').map((str) => str.trim());
    const { insurancesPlanList, ...config } = organizationConfig;
    const newConfiguration = {
      ...config,
      insurancesPlanList: [...insurancesPlanList, ...insurancesList],
    };
    setLoading(true);
    saveConfiguration(newConfiguration)
      .then(() => {
        setOrganizationConfig(newConfiguration);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setBulkInsuranceInput('');
        setLoading(false);
      });
  };

  const handleSubmitDeployConfigs = (event) => {
    event.preventDefault();
    const deplyConfigsSplit = `${bulkDeployConfigsInput}`.split(',').map((str) => str.trim());

    setLoading(true);
    addNewDeployConfigurations(deplyConfigsSplit)
      .then(() => {
        setDeployConfigsList((prev) => [...prev, ...deplyConfigsSplit]);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setBulkDeployConfigsInput('');
        setLoading(false);
      });
  };

  let template;
  if (loading) {
    template = <SimpleLoading />;
  } else if (organizationConfig && extendedSchema) {
    template = (
      <>
        <ConfigurationUI
          uiActiveSchema={uiActiveSchema}
          organizationConfig={organizationConfig}
          debouncedSave={debouncedSave}
          extendedSchema={shouldFetchDeployConfigsFromOptumBff ? extendedSchema : schema}
        />
        <form className="bulkForm" onSubmit={handleSubmitConfiguration}>
          <div>
            <label className="headLabel" htmlFor="bulkInsuranceList">
              Mass add of insurance plans
            </label>
            <p className="field-description">Provide comma separated insurance plans list</p>
          </div>
          <div className="bulkFormBody">
            <input
              disabled={!organizationConfig?.organizationType}
              type="text"
              id="bulkInsuranceList"
              value={bulkInsuranceInput}
              onChange={(e) => setBulkInsuranceInput(e.target.value)}
            />
            <button
              disabled={!organizationConfig?.organizationType}
              className="btn btn-info"
              type="submit"
            >
              Save
            </button>
          </div>
        </form>
        <form className="bulkForm" onSubmit={handleSubmitDeployConfigs}>
          <div>
            <label className="headLabel" htmlFor="bulkDeployConfigs">
              Mass add of Deploy Configurations
            </label>
            <p className="field-description">Provide comma separated deploy configurations list</p>
          </div>
          <div className="bulkFormBody">
            <input
              type="text"
              id="bulkDeployConfigs"
              value={bulkDeployConfigsInput}
              onChange={(e) => setBulkDeployConfigsInput(e.target.value)}
            />
            <button disabled={!bulkDeployConfigsInput} className="btn btn-info" type="submit">
              Save
            </button>
          </div>
        </form>
      </>
    );
  } else if (vimSignature && !organizationConfig) {
    template = <AddingProductToOrganizationStep />;
  } else {
    template = <ErrorIframe />;
  }
  return template;
};

const AddingProductToOrganizationStep = () => (
  <div className="info-block">
    <div className="info-block__title">
      You must first save the organization in order to edit a new app.
    </div>
    <div className="info-block__subtitle">
      Please save the organization, then edit it again to configure the new app.
    </div>
  </div>
);

const ConfigurationUI = ({ extendedSchema, organizationConfig, debouncedSave, uiActiveSchema }) => (
  <div className="">
    <Form
      schema={extendedSchema}
      uiSchema={uiActiveSchema}
      formData={organizationConfig}
      onChange={({ formData }) => debouncedSave(formData)}
    ></Form>
  </div>
);

const ErrorIframe = () => (
  <div className="info-block">
    <div className="info-block__title">Something went wrong :(</div>
    <div className="info-block__subtitle">Please refresh the page and try again</div>
  </div>
);

export default ConfigurationComponent;
