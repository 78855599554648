import {
  ReferralUtilityResponseReferralResult,
  FullAddressResponse,
  UrgenciesIetm,
  DiagnosisCodesResponse,
} from '../../api/optum/types';
import { getNpiIdentifierResponse } from './getNpiByIdentifierResponse';

export function getLeadingReachNotesFormating(
  response: ReferralUtilityResponseReferralResult,
): string {
  const {
    referralId = '',
    specialist,
    specialty,
    endDate,
    numberOfVisits,
    diagnosisCodes,
    referralReason,
    urgencies,
    // market,
    provider,
  } = response;

  const { identifiers, firstName, lastName, fullAddress, phone, fax, clinicLocation, tier } =
    specialist ?? {};
  const result = [
    noteTitleBlock({ referralId }),
    ` NPI: ${getNpiIdentifierResponse(identifiers)} `,
    ` Name: ${lastName ? lastName : ''} ${firstName ? firstName : ''} `,
    ` Practice name: ${clinicLocation} `,
    specialityTemplate(specialty),
    ...fullAddressTemplate(fullAddress!),
    ` Phone: ${phone} `,
    ` Fax: ${fax} `,
    ` Referral information: ${referralReason} `,
    ` End date: ${endDate || ''} `,
    ` Number of visits: ${numberOfVisits} `,
    ` Diagnosis: ${diagnosisCodesTemplate(diagnosisCodes)} `,
    urgencyTemplate(urgencies),
    ` Market: ${provider?.planName || ''} `,
    ` Tier: ${tier}`,
  ].join('|');
  return result;
}

function noteTitleBlock({ referralId }) {
  return `OptimalCare - referral ID  ${referralId} Target specialist: `;
}

function specialityTemplate(specialty: { code: string; description: string }[]) {
  let primary = '';
  const secondary = new Array<string>();
  specialty.forEach((s) => {
    if (s.code === 'primary-specialty') {
      primary = s.description;
    } else {
      secondary.push(s.description);
    }
  });

  return ` Specialty: ${primary ?? primary} (${secondary.join(', ')}) `;
}

function fullAddressTemplate(fullAddress: FullAddressResponse) {
  const result = [
    ` Address 1: ${fullAddress?.addressLine1} `,
    ` Address 2: ${fullAddress?.addressLine2} `,
    ` Address 3: `,
    ` City: ${fullAddress?.city} `,
    ` State: ${fullAddress?.state} `,
    ` Zip: ${fullAddress?.zipCode} `,
  ];
  return result;
}

function diagnosisCodesTemplate(diagnosisCodes: DiagnosisCodesResponse[]) {
  if (!diagnosisCodes.length) {
    return '';
  }
  return diagnosisCodes.map((d) => `code: ${d?.value} description: ${d?.description}`).join(', ');
}

function urgencyTemplate(urgencies: UrgenciesIetm[]) {
  const result = urgencies.map((u) => {
    return u.code;
  });

  return ` Urgency: (${result.join(', ')}) `;
}
