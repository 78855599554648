import React, { useEffect, useState } from 'react';
import { createLogger } from '../utils';
import { AppConfig } from '../types';
import { internalApi } from '../api';
import App from './App';

const logger = createLogger('referral-utility:AppWrapper');

const AppWrapper = () => {
  const [appConfig, setAppConfig] = useState<AppConfig>();

  useEffect(() => {
    internalApi.referralUtilityApi
      .getConfiguration()
      .then((data) => {
        const { configuration } = data;
        setAppConfig(configuration!);
      })
      .catch((error) => {
        logger.error(error);
      });
  }, []);

  if (!appConfig) {
    return null;
  }
  return <App appConfig={appConfig} />;
};

export default AppWrapper;
