export const withAsyncErrorHandling = <T extends (...args: any[]) => Promise<any>>(
  asyncFn: T,
): ((...args: Parameters<T>) => Promise<[Awaited<ReturnType<T>> | null, Error | null]>) => {
  return async (...args: Parameters<T>): Promise<[Awaited<ReturnType<T>> | null, Error | null]> => {
    try {
      return [await asyncFn(...args), null];
    } catch (error) {
      return [null, error as Error];
    }
  };
};

export const withErrorHandling = <T extends (...args: any[]) => any>(
  syncFn: T,
): ((...args: Parameters<T>) => [ReturnType<T> | null, Error | null]) => {
  return (...args: Parameters<T>): [ReturnType<T> | null, Error | null] => {
    try {
      return [syncFn(...args), null];
    } catch (error) {
      return [null, error as Error];
    }
  };
};
